import { useEffect, useState } from "react";
import useFsService from "../../../services/fsService";
import Spinner from "../../spinner/spinner";
import ErrorScreen from "../../error/error";
import Modal, { closeModal, openModal } from "../../modal/modal";
import ShareButtons from "../../shareBtns/shareBtns";
import allImages from "../../../utils/images";
import { NavLink, useParams } from "react-router-dom";
import FaceRank from "./faceRank/faceRank";
import FaceConditions from "./faceConds/faceConds";
import ImproveModal from "../../modal/ImproveModal";
import { useProgress } from "../../providers/ProgressContext";
import { useTranslation } from "react-i18next";

const ResultContent = ({ type }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { getResults, loading, error, errorText } = useFsService();
  const [resultData, setResultData] = useState(null);
  const [nickname, setNickname] = useState("");
  const [openModalName, setOpenModalName] = useState(null);
  const { updateProgress } = useProgress();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const res = await getResults(id || "");
        if (res.data.result) {
          setResultData(res.data.data.test_result);
          if (type !== "new") {
            setNickname(res.data.data.nickname);
          }

          setTimeout(() => {
            updateProgress({ resultsViewed: true });
          }, 3000);
        }
      } catch (e) { }
    };

    fetchResults();
  }, [id]);

  return (
    <>
      <div className="result">
        <div className="container">
          <div className="result__container">
            <h1>
              {type === "new"
                ? t("result.title", "Результаты")
                : t("result.nicknameTitle", "Результаты пользователя") + ` ${nickname}`}
            </h1>
            <div className="result__data">
              <section
                className="result__section result__index"
                style={{ gridArea: "topleft" }}
              >
                <FaceRank
                  loading={loading}
                  error={error}
                  errorText={errorText}
                  resultData={resultData}
                />
              </section>
              <section
                className="result__section result__age"
                style={{ gridArea: "topmid" }}
              >
                {renderSkinAge(
                  loading,
                  error,
                  errorText,
                  resultData,
                  setOpenModalName,
                  t
                )}
              </section>
              <button
                type="button"
                className="result__share btn btn--transblue"
                onClick={() => openModal(setOpenModalName, "share")}
                style={{ gridArea: "midmid" }}
              >
                <allImages.ShareIcon />
                <span>{t("result.share", "Поделиться результатами")}</span>
              </button>
              <NavLink
                className="result__next btn btn--blue"
                style={{ gridArea: "midright" }}
                to={type === "new" ? "/recs" : `recs/${id}`}
              >
                {t("result.toRecs", "Перейти к рекомендациям")}
              </NavLink>

              <section
                className="result__section result__details"
                style={{ gridArea: "bottom" }}
              >
                <div className="result__conds-top">
                  <h2>{t("result.details", "Детализация показателей")}</h2>
                  <div className="result__conds-points">
                    <span>{t("result.none", "Нет")}</span>
                    <span>{t("result.low", "Низ")}</span>
                    <span>{t("result.medium", "Ср")}</span>
                    <span>{t("result.high", "Выс")}</span>
                  </div>
                </div>
                <FaceConditions
                  loading={loading}
                  error={error}
                  errorText={errorText}
                  resultData={resultData}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
      {openModalName === "share" && (
        <Modal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("result.shareModalTitle", "Знаете, кому может помочь наш сервис?")}
        >
          <p className="modal__description">
            {t(
              "result.shareModalDescription",
              "Выберите одну из социальных сетей, чтобы поделиться с друзьями ссылкой на сервис"
            )}
          </p>
          <ShareButtons />
        </Modal>
      )}
      {openModalName === "improve" && (
        <ImproveModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("result.improveModalTitle", "Как улучшить показатели?")}
        />
      )}
    </>
  );
};

const renderSkinAge = (
  loading,
  error,
  errorText,
  resultData,
  setOpenModalName,
  t
) => {
  const getAgeLabel = (age) =>
    age % 10 === 1 && age % 100 !== 11
      ? `${age} ${t("result.year", "год")}`
      : age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)
        ? `${age} ${t("result.years", "года")}`
        : `${age} ${t("result.yearsPlural", "лет")}`;

  const spinner = loading ? <Spinner /> : null;
  const errorMessage = error ? (
    <ErrorScreen
      message={errorText || t("result.ageError", "Не удалось загрузить возраст кожи лица")}
    />
  ) : null;
  const content = resultData ? (
    <>
      <h3>{t("result.skinAge", "Возраст кожи")}</h3>
      <span>{getAgeLabel(resultData.age)}</span>
      <button
        className="result__improve"
        type="button"
        onClick={() => setOpenModalName("improve")}
      >
        {t("result.howToImprove", "Как улучшить?")}
      </button>
    </>
  ) : null;

  return (
    <>
      {errorMessage}
      {spinner}
      {content}
    </>
  );
};

export default ResultContent;
