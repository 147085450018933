import { useState, useEffect } from "react";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import emailjs from "emailjs-com";
import ErrorScreen from "../../error/error";
import Spinner from "../../spinner/spinner";
import SuccessScreen from "../../successScreen/successScreen";

const EmailEditor = ({ users, senderNickname }) => {
  const [subject, setSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(null);

  useEffect(() => {
    if (isSent) {
      const timer = setTimeout(() => {
        setIsSent(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isSent]);

  const handleCheckboxChange = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const resetForm = () => {
    setSubject("");
    setEmailContent("");
    setSelectedUsers([]);
  };

  const handleSelectAll = () => {
    setSelectedUsers(users.map((user) => user.id));
  };

  const handleClearAll = () => {
    setSelectedUsers([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    setError(null);

    for (const userId of selectedUsers) {
      const user = users.find((u) => u.id === userId);
      if (user) {
        if (!user.email) {
          console.log(`Не удалось отправить сообщение пользователю ${user.nickname}, почтовый адрес не существует`);
          continue;
        }
        const templateParams = {
          subject,
          message: emailContent,
          to_name: user.nickname,
          to_email: user.email,
          from_name: senderNickname,
        };

        try {
          const result = await emailjs.send("service_wc8o2lm", "template_63awg4q", templateParams, "kAlwAnKnH2K1fHHGG");
          if (result) {
            setIsSending(false);
            setIsSent(true);
            resetForm();
          }
        } catch (e) {
          setIsSending(false);
          setError(true);
        }
      }
    }
  };

  if (isSending) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorScreen message={error} />;
  }

  if (isSent) {
    return <SuccessScreen message="Письма успешно отправлены!" countdown={3} />;
  }

  return (
    <div className="profile__newsletter">
      <h2>Отправка рассылки</h2>
      <form onSubmit={handleSubmit}>
        <div className="profile__letter-title">
          <label htmlFor="subject">Тема письма:</label>
          <input
            id="subject"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Введите тему письма"
          />
        </div>
        <div className="profile__letter-text">
          <label>Содержимое письма:</label>
          <ReactQuill value={emailContent} onChange={setEmailContent} theme="snow" />
        </div>
        <div className="profile__letter-recievers">
          <h3>Выберите получателей:</h3>
          <ul>
            {users.map((user) => (
              <li key={user.id}>
                <label>
                  <input
                    type="checkbox"
                    value={user.id}
                    onChange={() => handleCheckboxChange(user.id)}
                    checked={selectedUsers.includes(user.id)}
                  />
                  {user.nickname} ({user.email})
                  <span className="entrance__checkbox"></span>
                </label>
              </li>
            ))}
          </ul>
          <div className="profile__letter-actions">
            <button className="btn btn--pink" type="button" onClick={handleSelectAll}>
              Выбрать всех
            </button>
            <button className="btn btn--blue" type="button" onClick={handleClearAll}>
              Очистить все
            </button>
          </div>
        </div>
        <button
          className="btn btn--transblue profile__letter-submit"
          type="submit"
          disabled={selectedUsers.length === 0 || !subject.trim() || !emailContent.trim()}
        >
          Отправить рассылку
        </button>
      </form>
    </div>
  );
};

export default EmailEditor;
