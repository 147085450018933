import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SideDashboard from "../dashboard/dashboard";
import allImages from "../../utils/images";
import TestNav from "../testNav/testNav";
import RecsContent from "../content/recs/recs";

const RecsPage = ({ type }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t("recs.metaDescription", "Рекомендации по уходу за кожей лица")}
        />
        <title>{t("recs.pageTitle", "Страница рекомендаций")}</title>
      </Helmet>

      <main>
        <aside className="dashboard">
          <SideDashboard type={type === "new" ? "test" : ""} />
        </aside>

        <NavLink className="mob__logo" to="/">
          <img src={allImages.DashLogoWebp} alt={t("recs.logoAlt", "Logo")} />
        </NavLink>

        {type === "new" && (
          <aside className="mob__nav">
            <TestNav />
          </aside>
        )}

        <div className="recs">
          <div className="recs__container">
            <RecsContent />
          </div>
        </div>
      </main>
    </>
  );
};

export default RecsPage;
