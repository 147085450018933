import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFsService from "../../../services/fsService";
import Spinner from "../../spinner/spinner";
import ErrorScreen from "../../error/error";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import EmailEditor from "./profile-email";

const ProfileAdmin = () => {
  const { t } = useTranslation();
  const [isEmailEditorOpen, setIsEmailEditorOpen] = useState(false);
  const { getInvitedList, error, loading, clearError, errorText } =
    useFsService();
  const [invitedData, setInvitedData] = useState({
    invited_link: null,
    list: [],
  });
  const [copyButtonText, setCopyButtonText] = useState(
    t("profileAdmin.copyButton.default", "Скопировать")
  );
  const [copyButtonDisabled, setCopyButtonDisabled] = useState(false);

  const rowRefs = useRef([]);

  useEffect(() => {
    clearError();
    const fetchInvitedList = async () => {
      try {
        const res = await getInvitedList();
        if (res.data.result) {
          setInvitedData(res.data.data || { invited_link: null, list: [] });
        }
      } catch (e) { }
    };
    fetchInvitedList();
  }, []);

  useEffect(() => {
    if (invitedData.list && invitedData.list.length > 0) {
      gsap.fromTo(
        rowRefs.current,
        {
          x: -200,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
        }
      );
    }
  }, []);

  const handleCopyClick = async () => {
    if (!invitedData.invited_link) {
      return;
    }

    const link = `${window.location.origin}${invitedData.invited_link}`;
    try {
      await navigator.clipboard.writeText(link);
      setCopyButtonText(t("profileAdmin.copyButton.success", "Успешно скопировано!"));
      setCopyButtonDisabled(true);
      setTimeout(() => {
        setCopyButtonText(t("profileAdmin.copyButton.default", "Скопировать"));
        setCopyButtonDisabled(false);
      }, 2000);
    } catch (err) {
      console.error("Ошибка при копировании ссылки:", err);
    }
  };

  const spinner = loading ? <Spinner /> : null;
  const errorScreen = error ? <ErrorScreen errorText={errorText} /> : null;
  const content =
    !loading && !error ? (
      <View invitedData={invitedData} />
    ) : null;

  return (
    <>
      <h1>{t("profileAdmin.title", "Приглашенные пользователи")}</h1>
      <div className="profile__link">
        <h2>{t("profileAdmin.inviteLink", "Ссылка приглашение")}</h2>
        {invitedData.invited_link ? (
          <div className="profile__invite">
            <a
              href={`${window.location.origin}${invitedData.invited_link}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${window.location.origin}${invitedData.invited_link}`}
            </a>
            <button
              type="button"
              onClick={handleCopyClick}
              disabled={copyButtonDisabled}
              className="btn btn--pink"
            >
              {copyButtonText}
            </button>
          </div>
        ) : (
          <span>{t("profileAdmin.noInviteLink", "Нет ссылки для приглашения")}</span>
        )}
      </div>
      {spinner}
      {errorScreen}
      {content}
      <button className="btn btn--blue profile__toggle-email" onClick={() => setIsEmailEditorOpen((prev) => !prev)}>
        {isEmailEditorOpen ? "Скрыть форму" : "Открыть форму для рассылки"}
      </button>
      {isEmailEditorOpen && (
        <div className="profile__email-editor">
          <EmailEditor users={invitedData.list} senderNickname={invitedData.my_nickname} />
        </div>
      )}
    </>
  );
};

const View = ({ invitedData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const list = invitedData.list || [];
  const [openIndex, setOpenIndex] = useState(null);

  const handleRowClick = (index, id) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const handleNavigate = (id) => {
    navigate(`/profile/tests/${id}`);
  };

  return (
    <table className="profile__invite-list">
      <thead>
        <tr>
          <th>{t("profileAdmin.table.email", "Почта")}</th>
          <th>{t("profileAdmin.table.nickname", "Никнейм")}</th>
          <th>{t("profileAdmin.table.phone", "Телефон")}</th>
          <th>{t("profileAdmin.table.telegram", "Telegram")}</th>
        </tr>
      </thead>
      <tbody>
        {list.map((invited, index) => (
          <tr
            key={index}
            className={openIndex === index ? "open" : "closed"} // 
            onClick={() => handleRowClick(index, invited.id)} // 
          >
            <td data-label={t("profileAdmin.table.email", "Почта")}>
              {invited.email}
            </td>
            <td
              data-label={t("profileAdmin.table.nickname", "Никнейм")}>
              <span
                className="profile__user btn btn--transblue"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigate(invited.id);
                }}
              >
                {invited.nickname}
              </span>
            </td>
            <td data-label={t("profileAdmin.table.phone", "Телефон")}>
              {invited.phone ? invited.phone : "нет данных"}
            </td>
            <td data-label={t("profileAdmin.table.telegram", "Telegram")}>
              {invited?.telegram_url ? (
                <a
                  href={invited.telegram_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--trans"
                  onClick={(e) => e.stopPropagation()}
                >
                  {t("profileAdmin.contact", "Связаться")}
                </a>
              ) : (
                <span>{t("profileAdmin.noTelegram", "Нет телеграма")}</span>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProfileAdmin;
