import { useAuthContext } from "../components/providers/authProvider";
import { useHttp } from "../hooks/httpHook";

export const useFsService = () => {
  const { accessToken, tempToken, fetchTempToken } = useAuthContext();
  const { loading, error, errorText, request, clearError } = useHttp();
  const _apiBase = "https://api.facescan.pro";

  // tokens
  const getValidToken = async () => {
    if (accessToken) {
      return accessToken;
    }

    if (tempToken) {
      return tempToken;
    }

    const token = await fetchTempToken();
    return token;
  };

  // login
  const sendLoginData = async (data) => {
    const res = await request(`${_apiBase}/users/login/`, "POST", data);
    return res;
  };

  // Register
  const sendRegData = async (data) => {
    const res = await request(`${_apiBase}/users/reg/`, "POST", data);
    return res;
  };

  // Recover
  const sendRecoveryData = async (data) => {
    const res = await request(`${_apiBase}/users/password_recovery/`, "POST", data)
    return res
  }

  // List of tests
  const getTestsList = async (id = "") => {
    const res = await request(`${_apiBase}/users/list_of_tests?user_id=${id}`);
    return res;
  };

  // Get Profile Data
  const getProfileData = async () => {
    const res = await request(`${_apiBase}/users/profile/`);
    return res;
  };

  // send profile data
  const sendProfileData = async (data) => {
    const res = await request(`${_apiBase}/users/profile/`, "POST", data)
    return res
  }

  // get invited list
  const getInvitedList = async () => {
    const res = await request(`${_apiBase}/users/list_invited`)
    return res
  }

  // new test
  const startNewTest = async () => {
    const res = await request(`${_apiBase}/core/start_new_test/`, "POST");
    return res.data.result;
  };

  // photo
  const postPhoto = async (image) => {
    const res = await request(`${_apiBase}/core/test_photo/`, "POST", {
      photo: image,
    });
    return res.data.result;
  };

  // get Test
  const getTestQuestions = async () => {
    const res = await request(`${_apiBase}/core/test/`);
    return res;
  };

  // send Test
  const postTestAnswers = async (answers) => {
    const res = await request(`${_apiBase}/core/test/`, "POST", {
      data: answers,
    });
    return res;
  };

  // get Results
  const getResults = async (id = "") => {
    const res = await request(`${_apiBase}/users/test?tp_id=${id}`);
    return res;
  };

  // getBasket
  const getBasket = async (id = "") => {
    const res = await request(`${_apiBase}/shop/basket?tp_id=${id}`);
    return res;
  };

  // putInBasket
  const putInBasket = async (count, code) => {
    const res = await request(`${_apiBase}/shop/basket/`, "PUT", {
      count: count,
      product_code: code,
    });
    return res.data.result;
  };

  // getOrderLink
  const getOrderLink = async () => {
    const res = await request(`${_apiBase}/shop/basket/link`);
    return res.data;
  };

  // send Region
  const postReg = async (selectedOption) => {
    const res = await request(`${_apiBase}/users/region/`, "POST", {
      region: selectedOption,
    });
    return res.data.result;
  };

  // send Language
  const postLang = async (selectedOption) => {
    const res = await request(`${_apiBase}/users/lang/`, "POST", {
      lang: selectedOption,
    });
    return res.data.result;
  };

  // get FAQ
  const getFaq = async () => {
    const res = await request(`${_apiBase}/users/faq/`);
    return res;
  };

  return {
    loading,
    error,
    errorText,
    clearError,
    postReg,
    postLang,
    startNewTest,
    postPhoto,
    getValidToken,
    getTestQuestions,
    postTestAnswers,
    getResults,
    getBasket,
    putInBasket,
    getOrderLink,
    getFaq,
    sendLoginData,
    sendRegData,
    getTestsList,
    getProfileData,
    sendProfileData,
    getInvitedList,
    sendRecoveryData,
  };
};

export default useFsService;
