import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../spinner/spinner";
import MobMenu from "../mobMenu/mobMenu";
import Footer from "../content/footer/footer";
import Header from "../content/header/header";
import ImprovePage from "../pages/ImprovePage";
import RulesPage from "../pages/RulesPage";
import PoliticsPage from "../pages/PoliticsPage";
import PhotoPage from "../pages/PhotoPage";
import TestPage from "../pages/TestPage";
import ResultPage from "../pages/ResultPage";
import RecsPage from "../pages/RecsPage";
import FaqPage from "../pages/FaqPage";
import EntrancePage from "../pages/EntrancePage";
import SuccessScreen from "../successScreen/successScreen";
import ProfilePage from "../pages/ProfilePage";
import NotFoundPage from "../404/404";

const MainPage = lazy(() => import("../pages/MainPage"));

const App = () => {
  const { t } = useTranslation();

  return (
    <Router basename="/">
      <div className="App">
        <div className="wrapper">
          <h1 className="visually-hidden">"FaceScan by Greenway"</h1>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header type={"main"} />
                    <MainPage />
                    <Footer type={"main"} />
                  </>
                }
              />
              <Route
                path="/entrance/:type"
                element={
                  <>
                    <Header />
                    <EntrancePage />
                    <Footer />
                  </>
                }
              />
              <Route path="/improve" element={<ImprovePage />} />
              <Route path="/rules" element={<RulesPage />} />
              <Route path="/privacy" element={<PoliticsPage />} />
              <Route path="/photo" element={<PhotoPage />} />
              <Route path="/test" element={<TestPage />} />
              <Route path="/result" element={<ResultPage type={"new"} />} />
              <Route
                path="/profile/tests/result/:id"
                element={<ResultPage />}
              />
              <Route
                path="/profile/tests/:id/result/:id"
                element={<ResultPage />}
              />
              <Route
                path="/profile/tests/result/:id/recs/:id"
                element={<RecsPage />}
              />
              <Route
                path="/profile/tests/:id/result/:id/recs/:id"
                element={<RecsPage />}
              />
              <Route path="/recs" element={<RecsPage type={"new"} />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route
                path="/success"
                element={
                  <SuccessScreen
                    message={t("success.message", "Возвращаемся на главную страницу")}
                    countdown={3}
                    redirectPath={"/"}
                  />
                }
              />
              <Route path="/profile/:type" element={<ProfilePage />} />
              <Route
                path="/profile/tests/:id"
                element={<ProfilePage type="tests" />}
              />
              <Route
                path="*"
                element={<NotFoundPage />}
              />
            </Routes>
          </Suspense>
          <MobMenu />
        </div>
      </div>
    </Router>
  );
};

export default App;
